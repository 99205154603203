import { Wysiwyg, Button } from "@/ui"
import { stripHtml } from "@/lib"
import Image from "next/image"
import clsx from "clsx"

export const config = {
	id: "mre_templates:slide_lp",
}
// title,excerpt,image,urlTag,url,urlContent,className,category,
const slideLP = ({ data }) => {
	return (
		<div className="lp-slider relative h-[850px] md:h-[800px] bg-[#fffaf3] before:content-[''] before:block before:w-[200px] before:h-[380px] md:before:h-[800px] md:before:w-[516px] before:bg-primary before:absolute before:right-0 top-0 md:before:bottom-0">
			<div className="container flex flex-col-reverse md:flex-row h-full">
				<div className="md:basis-1/2 md:mt-auto my-5 md:mb-20">
					<Wysiwyg
						as="div"
						html={data?.components?.[0].title?.value["#text"]}
						className={"lp-slider_title mb-5 md:mb-10"}
					/>
					<Wysiwyg
						as="p"
						html={stripHtml(data?.components?.[0].description?.value["#text"])}
						className="text-[20px] md:text-[22px] font-normal leading-7 mb-5 md:mb-10"
					/>
					<Button
						href={data?.components?.[0].link.url}
						target={data?.components?.[0].link.attributes.target}
						rel={data?.components?.[0].link.attributes.rel}
						id={data?.components?.[0].link.attributes.id}
						variant="mre"
						className={clsx(
							data?.components?.[0].link.attributes.className,
							"py-3 mb-5 md:mb-10 !normal-case"
						)}
					>
						{data?.components?.[0].link.title}
					</Button>
					<Wysiwyg
						as="div"
						html={data?.components?.[0].info?.value["#text"]}
						className="lp-slider_avantages"
					/>
				</div>
				<div className="absolute overflow-hidden w-[300px] h-[300px] md:w-[647px] md:h-[656px] right-0 top-20 md:top-auto md:bottom-0">
					<Image
						src={data?.components?.[0].image?.[0]._default}
						layout="fill"
						className="object-contain right-0 bottom-0"
					/>
				</div>
			</div>
		</div>
	)
}

export default slideLP
