import { forwardRef, useState } from "react"
import clsx from "clsx"
import { select } from "./theme"

export const SelectMre = forwardRef(
	(
		{
			name,
			isRequired,
			label = "label",
			list,
			selected,
			setSelected,
			hasError,
			errorMessage,
			className,
			variant = "mre",
			fullWidth = false,
		},
		ref
	) => {
		const [code, setCode] = useState()

		const extractCode = (option) => {
			const match = option.match(/\((.*?)\)/)
			return match ? match[1] : ""
		}

		const getCode = (list, value) => {
			const foundItem = list.find((item) => item.value == value)
			return foundItem ? foundItem.content : " "
		}

		return (
			<div ref={ref} className={clsx(select[variant].wrapper, className)}>
				{label && (
					<label className={select[variant].label} htmlFor="nom">
						{label}
						{isRequired && (
							<span
								className="font-bold mx-1 text-red-500"
								title="This field is required"
							>
								*
							</span>
						)}
					</label>
				)}
				<div className={!fullWidth ? select[variant].inputWrapper : "w-full relative"}>
					<select
						className={clsx(
							select[variant].input,
							hasError && select[variant].hasError,
							code && name == "indicatif" && "text-transparent"
						)}
						defaultValue={selected}
						onChange={(e) => {
							setSelected(e.target.value),
								setCode(extractCode(getCode(list, e.target.value)))
						}}
					>
						{list?.map((item) => (
							<option key={item.value} value={item.value}>
								{item.content}
							</option>
						))}
					</select>
					{code && name == "indicatif" && (
						<div className="absolute top-2 left-4">{code}</div>
					)}
				</div>
				{hasError && (
					<p
						className={clsx(
							fullWidth
								? select[variant].error.replace("md:w-7/12", "w-full")
								: select[variant].error
						)}
					>
						{errorMessage}
					</p>
				)}
			</div>
		)
	}
)
