import { Tab } from "@headlessui/react"
import clsx from "clsx"
import { Wysiwyg, Heading } from "@/ui"
import Image from "next/image"

export const config = {
	id: "mre_templates:packs",
}

const TabsComponent = ({ title, nodes, onClick }) => {
	return (
		<div className="w-full md:px-5 pb-5">
			<Heading variant="mre" className="text-left mb-10" icon="left">
				{title}
			</Heading>
			<Tab.Group>
				<Tab.List className="flex md:inline bg-transparent overflow-x-auto overflow-y-hidden">
					{nodes.map((node) => (
						<Tab
							onClick={() => {
								typeof onClick === "function" && onClick(node.id)
							}}
							key={node.id}
							className={"relative mr-10 pb-5 outline-none"}
						>
							{({ selected }) => (
								<>
									<div
										className={clsx(
											"relative mb-2 overflow-hidden rounded-md",
											selected ? "w-[140px] h-[86px]" : "w-[120px] h-[72px] opacity-50"
										)}
									>
										<Image
											src={node.image.src}
											alt={node.image.alt}
											layout="fill"
											className="w-full h-full object-cover"
										/>
									</div>
									<p
										className={clsx(
											selected && "font-bold",
											"text-sm text-center mx-auto"
										)}
									>
										{node.title}
									</p>
									<span
										className={clsx(
											// "absolute bottom-0 w-0 h-0 mx-auto border-l-[10px] border-l-transparent border-b-0 border-r-[10px] border-r-transparent  ",
											"absolute -bottom-[10px] w-5 h-5 bg-transparent border border-transparent rotate-45 rtl:-rotate-45 border-r-0 border-b-0",
											selected && "!bg-white border-yellow-500 z-[20]"
										)}
									></span>
								</>
							)}
						</Tab>
					))}
				</Tab.List>
				<Tab.Panels>
					{nodes.map((node) => (
						<Tab.Panel
							key={node.id}
							className="bg-white border border-primary md:p-8 p-4 focus:outline-none relative z-10"
						>
							<div className="md:flex">
								<div className="flex flex-col justify-center text-center  md:mr-8">
									{node.image && (
										<div className="relative w-[300px] h-[200px] md:w-[282px] md:h-[179px] overflow-hidden rounded-lg mx-auto">
											<Image
												src={node.image.src}
												alt={node.image.alt}
												layout="fill"
												className="w-full h-full object-cover"
											/>
										</div>
									)}
									{node.price && node.slug && (
										<div className="text-secondary">
											<span className="text-[40px] font-bold">{node.price}</span>
											<span className="text-xs font-bold">{node.slug}</span>
										</div>
									)}
									{node.price_info && (
										<span className="block bg-gradient-to-r from-secondary to-primary text-white rounded-md text-sm font-medium w-fit mx-auto py-1 px-2 -rotate-5 mb-7 md:mb-1">
											{node.price_info}
										</span>
									)}
								</div>
								{node.content.value["#text"] && (
									<div className="">
										<p className="text-md font-bold mb-3">{node.title}</p>
										<Wysiwyg as={"p"} html={node.content.value["#text"]} />
									</div>
								)}
							</div>
							{node.offre.value["#text"] && (
								<Wysiwyg
									as={"p"}
									className="bg-[#F8F8F8] p-4 md:p-[30px] mt-6"
									html={node.offre.value["#text"]}
								/>
							)}
						</Tab.Panel>
					))}
				</Tab.Panels>
			</Tab.Group>
		</div>
	)
}

const TabsContentWidget = ({ data }) => {
	let props = {
		title: data?.extra_field?.title,
		nodes: data?.components?.map((item, index) => ({
			id: index,
			title: item.title,
			content: item.content,
			price: item.price,
			slug: item.slug,
			price_info: item.price_info,
			offre: item.offre,
			image: {
				src: item.image[0]._default || null,
				width: item.image[0].meta?.width,
				height: item.image[0].meta?.height,
				alt: item.image[0].meta?.alt,
			},
		})),
	}

	return <TabsComponent {...props} />
}

export default TabsContentWidget
